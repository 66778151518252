import React, { useContext } from "react";
import * as S from "./OrderSummary.styles";
import Context from "../../context/Context";
import Modal from "../Modal/Modal";
import { useOrdersData } from "../../hooks/useOrdersData";
import { frequencyLabels } from "../../utils/constants/frequency";

const OrderSummary = props => {
  const { showOrderSummary, setShowOrderSummary } = useContext(Context);
  const { language = "english" } = props;

  const subtotalLabel = language === "german" ? "Zwischensumme" : "Subtotal";
  const shippingLabel = language === "german" ? "Versand" : "Shipping";
  const freeLabel = language === "german" ? "GRATIS" : "FREE";
  const taxesLabel = language === "german" ? "7 % MwSt." : "Taxes";
  const totalSavingsLabel =
    language === "german" ? "Gesamtersparnis" : "Total Savings";
  const orderTotalLabel =
    language === "german" ? "Gesamtbetrag" : "Order Total";
  const youHaveSavedLabel =
    language === "german" ? "Sie sparen" : "You’ve saved";
  const yourDiscountAppliedLabel =
    language === "german"
      ? "Ihr Rabatt wurde erfolgreich angewendet!"
      : "Your discount has successfully been applied!";

  const {
    orderItems,
    currency,
    subTotal,
    orderTotal,
    isFreeShipping,
    savings,
    taxes
  } = useOrdersData();

  const onClickHandler = () => {
    setShowOrderSummary(false);
  };

  return showOrderSummary ? (
    <Modal>
      <S.SummaryWrapper onClick={onClickHandler} />
      <S.SummaryContainer>
        <S.Cancel onClick={onClickHandler}>
          <S.CancelImage src="/images/cancel.svg" />
        </S.Cancel>
        {orderItems?.length > 0 && (
          <>
            <S.Items>
              {orderItems.map((item, index) => (
                <S.Item key={index}>
                  <S.ItemDetailsWrapper>
                    <S.ItemImageWrapper>
                      <S.ItemQuantity>{item?.quantity}</S.ItemQuantity>
                      <S.ItemImage src={item?.product_image} />
                    </S.ItemImageWrapper>
                    <S.ItemDetails>
                      <S.ItemDetailsInner>
                        <S.ItemTitle>{item?.productName}</S.ItemTitle>
                        {item?.schedule && language !== "german" ? (
                          <S.ItemFrequency>
                            <strong>Frequency:</strong>{" "}
                            {frequencyLabels[item?.schedule]}
                          </S.ItemFrequency>
                        ) : null}
                      </S.ItemDetailsInner>
                    </S.ItemDetails>
                  </S.ItemDetailsWrapper>
                  <S.ItemPrices>
                    <S.ItemPricesInner>
                      <S.ItemPricesRRP>
                        {item?.discounted_price
                          ? `${item?.perceivedRrp}`
                          : `${item?.freeGiftValue}`}
                      </S.ItemPricesRRP>
                      <S.ItemPricesDP>
                        {item?.discounted_price
                          ? `${item?.discountedPrice}`
                          : `${freeLabel}`}
                      </S.ItemPricesDP>
                    </S.ItemPricesInner>
                  </S.ItemPrices>
                </S.Item>
              ))}
            </S.Items>
            <S.Totals>
              <S.SubTotals>
                <S.SubTotalItem>
                  <S.TotalLabel>{subtotalLabel}</S.TotalLabel>
                  <S.TotalValue>{subTotal}</S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>{shippingLabel}</S.TotalLabel>
                  <S.TotalValue>
                    {isFreeShipping ? (
                      <>
                        <s>{currency}4.99</s> {freeLabel}
                      </>
                    ) : (
                      <>{currency}4.99</>
                    )}
                  </S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>{taxesLabel}</S.TotalLabel>
                  <S.TotalValue>{taxes}</S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>{totalSavingsLabel}</S.TotalLabel>
                  <S.TotalValue>-{savings}</S.TotalValue>
                </S.SubTotalItem>
              </S.SubTotals>
              <S.TotalsBorder />
              <S.GrandTotal>
                <S.TotalLabel>{orderTotalLabel}</S.TotalLabel>
                <S.TotalValue className="grand-total">
                  {orderTotal}
                </S.TotalValue>
              </S.GrandTotal>
              <S.SavingsMessage>
                {yourDiscountAppliedLabel}
                <br />
                {youHaveSavedLabel} {savings}
              </S.SavingsMessage>
            </S.Totals>
          </>
        )}
      </S.SummaryContainer>
    </Modal>
  ) : null;
};

export default OrderSummary;
